<!-- 填写完成 -->
<template>
  <div class="registerStepConBox">
    <div class="stepCon">
      <div class="chioseBackBox">
        <div class="headBox">
          <img
            class="promptIcon"
            src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/6713524e14095.svg"
            alt=""
          />
        </div>
        <div class="chioseConBox">
          <div class="titleBox">
            <div class="chioseTitleH1">
              {{ $fanyi("¡多谢了!") }}
            </div>
            <div class="chioseTitleFont">
              {{
                $fanyi(
                  "表单已成功发送。现在我们可以根据您的需要定制您的导入体验。"
                )
              }}
            </div>
          </div>

          <el-button
            type="primary"
            class="ContinuarBtn"
            @click="$fun.routerToPage('/')"
            >{{ $fanyi("去Rakumart") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ops from "../utils/opStore.js";
export default {
  data() {
    return {
      ops,
    };
  },
  components: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
@import "../css/registerCss.scss";
.registerStepConBox {
  @extend .dip;
  width: 100%;
  padding-top: 0;
  padding-bottom: 0 !important;

  .stepCon {
    .chioseTitleFont {
      margin-bottom: 56px !important;
    }
  }
}

.headBox {
  margin-bottom: 24px !important;
}

.chioseTitleH1 {
  margin-bottom: 12px !important;
}

.ContinuarBtn {
  margin-bottom: 0 !important;
}

.chioseConBox {
  padding-bottom: 0 !important;
}
</style>
