<!-- 调查问卷 -->
<template>
  <div class="flex registerConBox">
    <router-link to="/" class="logoBox">
      <img
        style="  width:185px;height: 46px;"
        id="logo"
        @click="$fun.routerToPage('/')"
        :src="require('@/assets/hometop/logo-rakumart-europe.svg')"
        alt=""
    /></router-link>
    <div class="flex cogBgBox fdcolmun jcenter" style="height: 100%;">
      <!-- 内容 -->
      <div class="regfisterRightConBox">
        <el-carousel
          ref="registerBodyCarouselRef"
          height="904px"
          :autoplay="false"
          :interval="0"
          arrow="never"
          :loop="false"
          class="registerBodyCarousel"
          indicator-position="outside"
        >
          <el-carousel-item>
            <!-- Rakumart经验 step1 -->
            <rakumartExperience ref="RakumartExperience" />
          </el-carousel-item>
          <el-carousel-item>
            <!-- 填写完成 step2 -->
            <completedFilling ref="CompletedFilling" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class=" backBox" @click="ops.backLastPage" v-if="ops.step != 2">
      <img
        src="https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202407/6683797671ecb.svg"
        alt=""
      />
      {{ $fanyi("退后一步") }}
    </div>
    <div class="rakuYear">
      © Rakumart 2024
    </div>
  </div>
</template>
<script>
import ops from "./utils/opStore.js";
import rakumartExperience from "./components/model-rakumartExperience.vue";
import completedFilling from "./components/model-completedFilling.vue";
export default {
  data() {
    return { ops };
  },
  components: {
    rakumartExperience,
    completedFilling,
  },
  watch: {
    "ops.step"(newv, oldv) {
      console.log(newv, oldv, this.ops.step);
      this.$refs.registerBodyCarouselRef.setActiveItem(newv - 1);
    },
  },
  computed: {},
  created() {},
  mounted() {
    this.ops.RakumartExperience = this.$refs.RakumartExperience;
    this.ops.CompletedFilling = this.$refs.CompletedFilling;
    this.$nextTick(() => {
      this.$refs.registerBodyCarouselRef.setActiveItem(this.ops.step - 1);
    });
  },
  methods: {},
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.registerConBox {
  // height: max-content;
  height: 100vh;
  min-height: 100%;
  overflow: auto;
  background-color: white;
  position: relative;

  .cogBgBox {
    flex: 1;
    height: 100%;
    background-image: url("https://rakumart-test.oss-cn-hangzhou.aliyuncs.com/202410/67135a59afcf7.svg");
    background-repeat: no-repeat;
    background-position: 0 174px;
    background-size: 100%;
    background-color: white;
  }

  .regfisterRightConBox {
    // padding-bottom: 40px;
  }

  .rakuYear {
    position: absolute;
    right: 100px;
    bottom: 42px;
  }

  .backBox {
    position: absolute;
    left: 40px;
    bottom: 42px;

    font-size: 16px;
    font-weight: 700;
    line-height: 24px; /* 150% */
    @extend .dip;
    img {
      rotate: -90deg;
      width: 24px;
      margin-right: 8px;
    }
  }
}

.registerBodyCarousel {
  /deep/.el-carousel__indicators {
    display: none;
  }
  /deep/.el-carousel__item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.stepIconList {
  gap: 12px;

  .stepBox {
    width: 30px;
    height: 8px;
    border-radius: 4px;
    background: var(--Gray-200, #eaecf0);
  }
  .active {
    background: var(--Gray-200, #1355ab);
  }
}

.logoBox {
  position: fixed;
  top: 20px;
  left: 40px;
}
</style>
